import React from "react";

//Data
import attachAppendixInputData from "../../../json/CreateCreditData/AttachAppendixDataInputs.json";

//Components
import FormDocsComponent from "../../FormDocsComponent/FormDocsComponent";

const AttachAppendixBody = () => {
  return (
    <section className="attach-doc-section">
      <div className="attach-doc-subcontainer">
        <div className="attach-form-supercontainer limit-width">
          <FormDocsComponent inputData={attachAppendixInputData.data[0]} />
        </div>
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/bgs/woman-deco-img-3.webp"
          className="profile-deco"
          alt=""
        />
      </div>
    </section>
  );
};

export default AttachAppendixBody;
