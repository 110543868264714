import React from "react";

//Style
import "./SurveyBody.css";

//Components
import SurveyFormComponent from "../SurveyFormComponent/SurveyFormComponent";

const SurveyBody = ({ userData }) => {
  return (
    <section className="survey-section">
      <div className="survey-subcontainer">
        <div className="form-with-steps-supercontainer">
          <SurveyFormComponent />
        </div>
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/bgs/man-deco-img.webp"
          className="profile-deco"
          alt=""
        />
      </div>
    </section>
  );
};

export default SurveyBody;
