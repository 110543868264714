import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

//Style
import "./SideMenusComponent.css";

const SideMenusComponent = ({ menuData }) => {
  const location = useLocation();

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    function handleResize() {
      const windowHeight = window.innerHeight;
      setIsSmallScreen(windowHeight < 820);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`side-menu-container`}>
      <div
        className={`side-menu-subcontainer ${
          isSmallScreen && "sms-remove-right-padding"
        }`}
      >
        <div className="logo-img-container">
          <img className="logo-img" src="/assets/logo.webp" alt="" />
        </div>
        <div className="line"></div>
        <div
          className={`menus-container ${isSmallScreen && "mc-show-scroll"} ${
            menuData.length <= 6 && "mc-hide-scroll"
          }`}
          style={{ overflow: showScroll ? "auto" : "hidden" }}
          onMouseEnter={() => setShowScroll(true)}
          onMouseLeave={() => setShowScroll(false)}
        >
          {menuData.map((option, i) => {
            return (
              <Link
                title={option.title}
                key={i}
                to={option.redirect}
                className={`option-container ${
                  option.redirect === location.pathname && "current-select"
                }`}
              >
                <img
                  src={
                    option.redirect === location.pathname
                      ? option.imgSelect
                      : option.img
                  }
                  alt={option.altImg}
                  className="option-icon"
                />
                <p className="title-option">{option.text}</p>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SideMenusComponent;
