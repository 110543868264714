import React, { useState } from "react";

//Style
import "./MoreInfoDataFormBody.css";

//Data
import moreInfoDataInputs from "../../json/CreateCreditData/MoreInfoDataFormInputs.json";

//Components
import FormWithStepsComponent from "../FormWithStepsComponent/FormWithStepsComponent";

const MoreInfoDataFormBody = () => {
  return (
    <section className="more-info-data-form-section">
      <div className="more-info-data-form-subcontainer">
        <div className="form-with-steps-supercontainer">
          <FormWithStepsComponent
            inputData={moreInfoDataInputs.data[0]}
            hasSteps={false}
            hasRestoreBtn={false}
          />
        </div>
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/bgs/woman-deco-img-2.webp"
          className="profile-deco"
          alt=""
        />
      </div>
    </section>
  );
};

export default MoreInfoDataFormBody;
