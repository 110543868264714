import React, { useState, useEffect, useCallback } from "react";
import "./CreditDetailsTableComponent.css";

const CreditDetailsTableComponent = ({
  getMaxData,
  getPaginationInit,
  maxData,
  credits,
  totalCreditsNum,
  filterData,
  isPopup,
  currentPath,
  fields,
}) => {
  const [pageCounter, setPageCounter] = useState(1);
  const [limit, setLimit] = useState(0);
  const [type, setType] = useState("next");
  const [hoveredButtonId, setHoveredButtonId] = useState(null);

  const sendMaxData = (e) => {
    getMaxData(e.target.value);
  };

  const sendPaginationInit = useCallback(() => {
    if (type === "next") {
      const nextVal = maxData * pageCounter - maxData;
      getPaginationInit(nextVal);
    } else if (type === "prev") {
      const prevVal = maxData * pageCounter - maxData;
      getPaginationInit(prevVal);
    }
  }, [getPaginationInit, maxData, pageCounter, type]);

  useEffect(() => {
    const sendPaginationInit = () => {
      if (type === "next") {
        const nextVal = maxData * pageCounter - maxData;
        getPaginationInit(nextVal);
      } else if (type === "prev") {
        const prevVal = maxData * pageCounter - maxData;
        getPaginationInit(prevVal);
      }
    };

    sendPaginationInit();
    // eslint-disable-next-line
  }, [pageCounter]);

  useEffect(() => {
    const totalPagesNum = Math.ceil(totalCreditsNum / maxData);
    setLimit(totalPagesNum);
    if (pageCounter >= limit) {
      setPageCounter(1);
    }
  }, [totalCreditsNum, maxData, limit, pageCounter]);

  const sendPaginationInitNext = () => {
    pageCounter < limit && setPageCounter(pageCounter + 1);
    setType("next");
  };

  const sendPaginationInitPrev = () => {
    pageCounter > 1 && setPageCounter(pageCounter - 1);
    setType("prev");
  };

  const handleMouseEnter = (btnId) => {
    setHoveredButtonId(btnId);
  };

  const handleMouseLeave = () => {
    setHoveredButtonId(null);
  };

  return (
    <>
      <div className="credit-detail-table-container">
        <table className="cdt-table-container">
          <tbody className="cdt-table-body">
            <tr className="cdt-table-row">
              {fields &&
                fields.map((field, i) => (
                  <td key={i} className="cdt-table-header-title">
                    {field.name}
                  </td>
                ))}
            </tr>
            {credits.map((credit, rowIndex) => (
              <tr key={rowIndex} className="cdt-table-row">
                {credit.map((field, buttonIndex) => {
                  if (field.is_action_btn) {
                    return (
                      <td
                        className={`cdt-table-header-title ${
                          field.btns.length > 1 && "actions-btn"
                        }`}
                        key={buttonIndex}
                      >
                        {field.btns.map((btn, btnIndex) => {
                          const btnId = `${rowIndex}-${buttonIndex}-${btnIndex}`;
                          return (
                            <button
                              className={`cdt-tht-action-btn ${
                                btn.has_text ? "has_text_radius" : ""
                              } ${btn.buttonType === "danger" ? "danger" : ""}`}
                              onClick={btn.function}
                              title={btn.title}
                              key={btnId}
                              onMouseEnter={() => handleMouseEnter(btnId)}
                              onMouseLeave={handleMouseLeave}
                            >
                              {btn.has_text && (
                                <p className="cdt-tht-action-btn-text">
                                  {btn.text}
                                </p>
                              )}
                              <img
                                className="cdt-tht-action-btn-icon"
                                src={
                                  hoveredButtonId === btnId
                                    ? btn.icon_src_hover
                                    : btn.icon_src
                                }
                                alt=""
                              />
                            </button>
                          );
                        })}
                      </td>
                    );
                  } else if (field.is_field_btn) {
                    return (
                      <td
                        className="cdt-table-header-title centered"
                        key={buttonIndex}
                      >
                        {field.btns.map((btn, btnIndex) => {
                          const btnId = `${rowIndex}-${buttonIndex}-${btnIndex}`;
                          return (
                            <button
                              className={`cdt-tht-action-btn ${
                                btn.has_text ? "has_text_radius" : ""
                              }`}
                              onClick={btn.function}
                              title={btn.title}
                              key={btnId}
                              onMouseEnter={() => handleMouseEnter(btnId)}
                              onMouseLeave={handleMouseLeave}
                            >
                              {btn.has_text && (
                                <p className="cdt-tht-action-btn-text">
                                  {btn.text}
                                </p>
                              )}
                              <img
                                className="cdt-tht-action-btn-icon"
                                src={
                                  hoveredButtonId === btnId
                                    ? btn.icon_src_hover
                                    : btn.icon_src
                                }
                                alt=""
                              />
                            </button>
                          );
                        })}
                      </td>
                    );
                  } else if (field.field_content !== null) {
                    return (
                      <td
                        key={buttonIndex}
                        className={`cdt-table-header-title ${
                          field.field_type === "danger" && "danger-txt"
                        }`}
                      >
                        {field.field_content}
                      </td>
                    );
                  }
                  return null;
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!isPopup && (
        <div className="cdt-pagination-elms">
          <div className="pagination-btns-container">
            <div className="pagination-btns">
              <button
                title="Anterior"
                className="pagination-btn"
                onClick={sendPaginationInitPrev}
              >
                {"<"}
              </button>
              {pageCounter}
              <button
                title="Siguiente"
                className="pagination-btn"
                onClick={sendPaginationInitNext}
              >
                {">"}
              </button>
            </div>
            <div className="pagination-info">
              1 - {credits.length} de {totalCreditsNum}
            </div>
            <div className="num-elems-page">
              <select
                className="select-max-data"
                onChange={sendMaxData}
                name=""
                id=""
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreditDetailsTableComponent;
