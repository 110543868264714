import React from "react";

//Style
import "./LoadingScreenComponent.css";

const LoadingScreenComponent = ({ hasComplementTxt }) => {
  return (
    <div className={`loading-container ${hasComplementTxt ? "has-txt" : ""}`}>
      <img
        className="loading-logo"
        src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/home/assets/logo.webp"
        alt=""
      />
      <div className="overlay">
        <div className="bar"> </div>
        <div className="bar"> </div>
        <div className="bar"> </div>
      </div>
      {hasComplementTxt && (
        <h1 className="loading-complement-txt">
          Estamos gestionando tu información, no cierres esta pestaña...
        </h1>
      )}
    </div>
  );
};

export default LoadingScreenComponent;
