import React, { useContext } from "react";

//Style
import "./ClientHome.css";

//Data
import counterTempData from "../../json/Dashboard/TempCounterData.json";
import listComponentData from "../../json/Dashboard/ListComponentData.json";

//Component
import NavbarComponent from "../../components/NavbarComponent/NavbarComponent";
import SideMenusComponent from "../../components/SideMenusComponent/SideMenusComponent";
import DashboardBody from "../../components/DashboardBody/DashboardBody";
import LoadingScreenComponent from "../../components/LoadingScreenComponent/LoadingScreenComponent";
import NavbarMobile from "../../components/NavbarMobile/NavbarMobile";
import NavbarMobileBottom from "../../components/NavbarMobileBotton/NavbarMobileBottom";
import VersionTxtComponent from "../../components/VersionTxtComponent/VersionTxtComponent";

//Context
import { LoadingContext } from "../../context/LoadingContext";

const ClientHome = ({ userData, userMenuData }) => {
  const { loading } = useContext(LoadingContext);

  return (
    <>
      {loading && <LoadingScreenComponent />}
      <div className={`body-container ${loading ? "no-scroll" : ""}`}>
        <VersionTxtComponent />
        <div className="body-subcontainer">
          <NavbarComponent userData={userData} />
          <NavbarMobile userData={userData} menuData={userMenuData} />
          <DashboardBody
            counterData={counterTempData.data}
            fetchData={counterTempData.complementData}
            listComponentData={listComponentData.data}
          />
          <NavbarMobileBottom />
        </div>
        <SideMenusComponent menuData={userMenuData} />
      </div>
    </>
  );
};

export default ClientHome;
