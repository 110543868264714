import React, { useContext, useState } from "react";

//Data
import popupDataBtn from "../../json/LoginScreen/button.json";

//Components
import NavbarComponent from "../../components/NavbarComponent/NavbarComponent";
import SideMenusComponent from "../../components/SideMenusComponent/SideMenusComponent";
import LoadingScreenComponent from "../../components/LoadingScreenComponent/LoadingScreenComponent";
import NavbarMobile from "../../components/NavbarMobile/NavbarMobile";
import NavbarMobileBottom from "../../components/NavbarMobileBotton/NavbarMobileBottom";
import RenovationTableBody from "../../components/RenovationTableBody/RenovationTableBody";
import VersionTxtComponent from "../../components/VersionTxtComponent/VersionTxtComponent";
import PopUpComponent from "../../components/PopUpComponent/PopUpComponent";
import PopupWithFormComponent from "../../components/PopupWithFormComponent/PopupWithFormComponent";

//Context
import { LoadingContext } from "../../context/LoadingContext";
import { PopupContext } from "../../context/PopupContext";

const RenovationTableScreen = ({ userData, userMenuData }) => {
  const { loading } = useContext(LoadingContext);

  const { showPopup, popupData } = useContext(PopupContext);

  const [showPopupWithForm, setShowPopupWithForm] = useState(false);

  const [popupWithFormData, setpopupWithFormData] = useState({});

  const getDataFromCreditTable = (data, showPopup, type) => {
    if (type) {
      switch (type.type) {
        case "add_observation":
          setpopupWithFormData(data);
          setShowPopupWithForm(showPopup);
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      {loading && <LoadingScreenComponent />}
      {showPopupWithForm && (
        <PopupWithFormComponent
          getDataFromCreditTable={getDataFromCreditTable}
          popupWithFormData={popupWithFormData}
          hasDescription={true}
          description={
            "Al seleccionar el motivo de no renovación, el crédito se eliminará automáticamente del listado."
          }
        />
      )}
      {showPopup && (
        <PopUpComponent content={popupData} btnData={popupDataBtn.data[0]} />
      )}
      <div className="body-container">
        <VersionTxtComponent />
        <div className="body-subcontainer">
          <NavbarComponent userData={userData} />
          <NavbarMobile userData={userData} menuData={userMenuData} />
          <RenovationTableBody
            getDataFromCreditTable={getDataFromCreditTable}
            userData={userData}
          />
          <NavbarMobileBottom />
        </div>
        <SideMenusComponent menuData={userMenuData} />
      </div>
    </>
  );
};

export default RenovationTableScreen;
