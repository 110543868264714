// Libraries

import { createContext } from "react";

// Functions

/**
 * Creates the context that will allow use the reducer's values {dispatch and log}.
 */

export const LoadingContext = createContext();