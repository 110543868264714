import React, { useState, useEffect, useContext } from "react";
import { BACKEND_API } from "../../constants/backendApi";

//Style
import "./PopupWithTableComponent.css";

//Components
import CreditDetailsTableComponent from "../CreditDetailsTableComponent/CreditDetailsTableComponent";

//Context
import { PopupContext } from "../../context/PopupContext";

const PopupWithTableComponent = ({
  dataPopup,
  getDataFromCreditTable,
  popupTableFields,
  hasCustomBtn,
  popupTitle,
  hasDescription,
  description,
  type,
}) => {
  const [maxData, setMaxData] = useState(10);

  const { setShowPopup, setPopupData } = useContext(PopupContext);

  const [, setInitPage] = useState(0);

  const [creditList, setCreditList] = useState([]);

  const [totalCredits] = useState(0);

  const token = localStorage.getItem("token");

  const [filterData] = useState({
    type: "",
    document_plate: "",
    advisor: "",
    insurer: "",
    credit_state: "En proceso",
    next_to_renew: "",
  });

  useEffect(() => {
    dataPopup && dataPopup.length > 0 && setCreditList(dataPopup);
  }, [dataPopup]);

  const getMaxDataValue = (maxDataVal) => {
    setMaxData(maxDataVal);
  };

  const getPaginationData = (initPageVal) => {
    setInitPage(initPageVal);
  };

  const closePopup = () => {
    localStorage.removeItem("docusignId");
    localStorage.removeItem("docusignCreditId");
    getDataFromCreditTable([], false, { type: type });
  };

  const handleSubmit = () => {
    switch (type) {
      case "resend_docusign":
        const docusignId = localStorage.getItem("docusignId");
        const docusignCreditId = localStorage.getItem("docusignCreditId");

        try {
          fetch(`${BACKEND_API}/api/docusing-resend-envelopes`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              envelopesDcsId: docusignId,
              idCredit: docusignCreditId,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data && data.data === "Documento enviado") {
                setShowPopup(true);
                setPopupData({
                  title: "Enviado Exitosamente",
                  info: "Documento enviado exitosamente.",
                  type: "correct",
                });
                localStorage.removeItem("docusignId");
                getDataFromCreditTable([], false, { type: type });
              } else {
                setShowPopup(true);
                setPopupData({
                  title: "Enviado Exitosamente",
                  info: data?.data,
                  type: "error",
                });
                localStorage.removeItem("docusignId");
                getDataFromCreditTable([], false, { type: type });
              }
            });
        } catch (err) {
          console.log(err);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="popup-with-table-component">
      <div className="popup-with-table-container">
        <div className="popup-with-table-subcontainer">
          <div className="pwt-title-container">
            <h1 className="pwt-title">{popupTitle}</h1>
          </div>
          {hasDescription && (
            <div className="pwt-description-txt-container">
              <p className="pwtdt-txt">{description}</p>
            </div>
          )}
          <div className="pwt-table-container">
            <CreditDetailsTableComponent
              getMaxData={getMaxDataValue}
              maxData={maxData}
              getPaginationInit={getPaginationData}
              credits={creditList}
              totalCreditsNum={totalCredits}
              filterData={filterData}
              isPopup={true}
              currentPath={"/admin/creditos-mora"}
              fields={popupTableFields}
            />
          </div>
          <div className="pwt-btns-container">
            <button
              onClick={closePopup}
              className="pwt-btn-close"
              title="Cerrar"
            >
              Cerrar
            </button>
            {hasCustomBtn && (
              <button className="pwt-btn-close submit" onClick={handleSubmit}>
                Reenviar
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupWithTableComponent;
