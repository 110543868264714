import React from "react";

//Style
import "./AttachExampleComponent.css";

const AttachExampleComponent = ({ adviceData }) => {
  return (
    <div className="attach-example-container">
      <div className="attach-example-subcontainer">
        <div className="ae-txt-container">
          <img
            className="advice-txt-icon"
            src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/sign-error.webp"
            alt=""
          />
          <h1 className="advice-txt">{adviceData.textAdvice}</h1>
        </div>
        <img className="advice-img" src={adviceData.imgEx} alt="" />
      </div>
    </div>
  );
};

export default AttachExampleComponent;
