import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Components
import NavbarComponent from "../../components/NavbarComponent/NavbarComponent";
import SideMenusComponent from "../../components/SideMenusComponent/SideMenusComponent";
import AttachPolicyBody from "../../components/AttachPolicyBody/AttachPolicyBody";
import LoadingScreenComponent from "../../components/LoadingScreenComponent/LoadingScreenComponent";
import NavbarMobile from "../../components/NavbarMobile/NavbarMobile";
import NavbarMobileBottom from "../../components/NavbarMobileBotton/NavbarMobileBottom";
import VersionTxtComponent from "../../components/VersionTxtComponent/VersionTxtComponent";

//Context
import { LoadingContext } from "../../context/LoadingContext";

const AttachPolicyScreen = ({ userData, userMenuData }) => {
  const { loading, hasComplementTxt } = useContext(LoadingContext);

  const history = useNavigate();

  const currentStep = localStorage.getItem("latestStep");

  const processType = localStorage.getItem("processType");

  useEffect(() => {
    if (processType === "new-credit") {
      switch (currentStep) {
        case "attach-document":
          history("/admin/crear-credito/adjuntar-poliza");
          break;
        case "attach-policy":
          history("/admin/crear-credito/adjuntar-anexo");
          break;
        case "attach-appendix":
          history("/admin/crear-credito/formulario");
          break;
        case "complement-data":
          history("/admin/crear-credito/formulario-confirmar");
          break;
        default:
          break;
      }
    } else if (processType === "renovation") {
      switch (currentStep) {
        case "validate-document":
          history("/admin/crear-credito/adjuntar-poliza");
          break;
        case "attach-policy":
          history("/admin/crear-credito/adjuntar-anexo");
          break;
        case "attach-appendix":
          history("/admin/crear-credito/verificar-datos");
          break;
        case "verify-data":
          history("/admin/crear-credito/formulario-renovacion");
          break;
        case "complement-data-renovation":
          history("/admin/crear-credito/formulario-renovacion-confirmar");
          break;
        default:
          break;
      }
    }
  }, [currentStep, history, processType]);

  return (
    <>
      {loading && (
        <LoadingScreenComponent hasComplementTxt={hasComplementTxt} />
      )}
      <div className="body-container">
        <VersionTxtComponent />
        <div className="body-subcontainer">
          <NavbarComponent userData={userData} />
          <NavbarMobile userData={userData} menuData={userMenuData} />
          <AttachPolicyBody />
          <NavbarMobileBottom />
        </div>
        <SideMenusComponent menuData={userMenuData} />
      </div>
    </>
  );
};

export default AttachPolicyScreen;
