import React from "react";

//Style
import "./PopUpComponent.css";

//Component
import ButtonComponent from "../ButtonComponent/ButtonComponent";

const PopUpComponent = ({ content, btnData }) => {
  return (
    <div className="popup-bg">
      <div className="popup-container">
        {content.hasTitle && <h1 className="popup-title">{content.title}</h1>}
        <img
          className="popup-icon"
          src={
            content.type === "error"
              ? "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/sign-error.webp"
              : content.type === "correct"
              ? "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/check-icon.webp"
              : ""
          }
          alt=""
        />
        {content.hasInfoArr && (
          <div className="info-arr-container">
            {content.infoArr.map((infoTxt, i) => {
              return (
                <div className="info-arr-container">
                  <p className="popup-msg" key={i}>
                    {infoTxt}
                  </p>
                </div>
              );
            })}
          </div>
        )}
        <p className="popup-msg">{content.info}</p>
        {content.type !== "option" && <ButtonComponent btnData={btnData} />}
        {content.showOptionBtn && content.type === "option" && (
          <div className="popup-option-container">
            {content.btnData.map((btn, i) => {
              return <ButtonComponent key={i} btnData={btn} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default PopUpComponent;
