import React, { useContext, useState } from "react";

//Data
import popupDataBtn from "../../json/LoginScreen/button.json";

//Components
import NavbarComponent from "../../components/NavbarComponent/NavbarComponent";
import SideMenusComponent from "../../components/SideMenusComponent/SideMenusComponent";
import CertificationBody from "../../components/CertificationBody/CertificationBody";
import LoadingScreenComponent from "../../components/LoadingScreenComponent/LoadingScreenComponent";
import NavbarMobile from "../../components/NavbarMobile/NavbarMobile";
import NavbarMobileBottom from "../../components/NavbarMobileBotton/NavbarMobileBottom";
import VersionTxtComponent from "../../components/VersionTxtComponent/VersionTxtComponent";
import PopupWithTableComponent from "../../components/PopupWithTableComponent/PopupWithTableComponent";
import PopUpComponent from "../../components/PopUpComponent/PopUpComponent";

//Context
import { LoadingContext } from "../../context/LoadingContext";
import { PopupContext } from "../../context/PopupContext";

const CertificationScreen = ({ userData, userMenuData }) => {
  const { showPopup, popupData } = useContext(PopupContext);

  const { loading } = useContext(LoadingContext);

  const [dataPopup, setDataPopup] = useState(null);

  const [showPopupWithTable, setShowPopupWithTable] = useState(false);

  const tableFields = [
    {
      name: "Valor Cuota",
    },
    {
      name: "Dias de Mora",
    },
    {
      name: "Referencia de Pago en Efecty",
    },
  ];

  const getDataFromCreditTable = (data, showPopup) => {
    const creditsNewArr = data.map((credit) => [
      {
        field_content: credit.ValorCuota.toLocaleString("es-CO", {
          style: "currency",
          currency: "COP",
        }),
      },
      {
        field_content: credit.DiasMora,
      },
      {
        field_content: credit.Referenciadepago,
      },
    ]);

    setDataPopup(creditsNewArr);
    setShowPopupWithTable(showPopup);
  };

  return (
    <>
      {loading && <LoadingScreenComponent />}
      {showPopupWithTable && (
        <PopupWithTableComponent
          getDataFromCreditTable={getDataFromCreditTable}
          dataPopup={dataPopup}
          popupTableFields={tableFields}
          hasCustomBtn={false}
          popupTitle={"Cuotas en Mora"}
          hasDescription={false}
        />
      )}
      {showPopup && (
        <PopUpComponent content={popupData} btnData={popupDataBtn.data[0]} />
      )}
      <div className="body-container">
        <VersionTxtComponent />
        <div className="body-subcontainer">
          <NavbarComponent userData={userData} />
          <NavbarMobile userData={userData} menuData={userMenuData} />
          <CertificationBody
            getDataFromCreditTable={getDataFromCreditTable}
            userData={userData}
          />
          <NavbarMobileBottom />
        </div>
        <SideMenusComponent menuData={userMenuData} />
      </div>
    </>
  );
};

export default CertificationScreen;
