import React, { useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

//Style
import "./BarGraphComponent.css";

const BarGraphComponent = ({ barGraphData, selectedYear }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Número de Créditos por Mes",
      },
    },
  };

  const labels = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: selectedYear,
        data: barGraphData,
        backgroundColor: "#0969FE",
      },
    ],
  };

  const containerRef = useRef(null);

  const handleScroll = () => {
    const container = containerRef.current;
    const scrollLeft = container.scrollLeft;
    const elements = document.querySelectorAll(".scroll-page");

    elements.forEach((element) => {
      const elementLeft = element.offsetLeft;
      if (
        scrollLeft >= elementLeft &&
        scrollLeft < elementLeft + element.clientWidth
      ) {
        element.classList.add("focused");
      } else {
        element.classList.remove("focused");
      }
    });
  };

  return (
    <div className="bar-graph-container">
      <h1 className="bg-title">Créditos Desembolsado por Mes/Año Actual</h1>
      <div className="bg-info-container">
        La siguiente gráfica contiene los créditos que fueron desembolsados a la
        aseguradora en cada uno de los meses del año actual. Estos créditos
        pueden estar actualmente en alguno de los siguientes estados en nuestro
        sistema: vigente, cancelados, paz y salvo, en proceso de cancelación.
      </div>
      <div className="bg-subcontainer">
        <div className="bg-elms-container">
          <div
            className="scroll-container"
            ref={containerRef}
            onScroll={handleScroll}
          >
            <div className="scroll-page">
              <Bar options={options} data={data} />
            </div>
          </div>
        </div>
      </div>
      <div className="other-description-container">
        *Los créditos desembolsados pueden pertenecer a créditos creados en
        nuestro sistema de meses anteriores al seleccionado
      </div>
    </div>
  );
};

export default BarGraphComponent;
