import React from "react";

const SimulatorPdf = ({
  totalPremium,
  paymentPremium,
  loanAmount,
  interestRate,
  termMonth,
  isWithholding,
  monthlyFee,
  paymentDate,
  diffMonths,
  initDate,
}) => {
  const formatDate = new Date(`${paymentDate}T00:00:00`);

  const interestRatePercetage = interestRate / 100;
  const withholdingPercentage = 0.07;

  let capitalBalance = 0;
  let capitalPayment = 0;

  let simulatorArr = [];

  if (termMonth > 0) {
    simulatorArr = Array.from({ length: termMonth }, () => Array(6).fill(0));

    for (let i = 0; i < simulatorArr.length; i++) {
      const newDate = new Date(formatDate);
      newDate.setMonth(formatDate.getMonth() + i);

      const day = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();

      simulatorArr[i][1] = `${day}/${month}/${year}`;
      simulatorArr[i][2] = loanAmount * interestRatePercetage;
      simulatorArr[i][3] = loanAmount - (monthlyFee - simulatorArr[i][2]);
      simulatorArr[i][4] = isWithholding
        ? simulatorArr[i][2] * withholdingPercentage
        : 0;
      simulatorArr[i][5] = monthlyFee + 2500 - simulatorArr[i][4];
      capitalPayment = monthlyFee - simulatorArr[0][2];
      capitalBalance = simulatorArr[0][3];

      if (i !== 0) {
        for (let i = 1; i < simulatorArr.length; i++) {
          if (i === 1) {
            simulatorArr[i][2] =
              (loanAmount - capitalPayment) * interestRatePercetage;
            capitalPayment = monthlyFee - simulatorArr[i][2];
            simulatorArr[i][3] = capitalBalance - capitalPayment;
            simulatorArr[i][4] = isWithholding
              ? simulatorArr[i][2] * withholdingPercentage
              : 0;
            simulatorArr[i][5] = monthlyFee + 2500 - simulatorArr[i][4];
            capitalBalance = simulatorArr[i][3];
          } else {
            simulatorArr[i][2] = capitalBalance * interestRatePercetage;
            capitalPayment = monthlyFee - simulatorArr[i][2];
            simulatorArr[i][3] = capitalBalance - capitalPayment;
            simulatorArr[i][4] = isWithholding
              ? simulatorArr[i][2] * withholdingPercentage
              : 0;
            simulatorArr[i][5] = monthlyFee + 2500 - simulatorArr[i][4];
            capitalBalance = simulatorArr[i][3];
          }
        }
      }
    }
  }

  const elements = [];

  for (let index = 0; index < termMonth; index++) {
    const currentDate = new Date();

    const dateParts = simulatorArr[index][1].split("/");
    const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;

    const tableDate = new Date(formattedDate);

    elements.push(
      <tr
        style={{
          borderBottom: "1px solid #014da1",
          textAlign: "center",
        }}
      >
        <td
          style={{
            borderRight: "1px solid #014da1",
            padding: "0.5rem 0",
            borderBottom: "1px solid #014da1",
          }}
        >
          {index + 1}
        </td>
        <td
          style={{
            borderRight: "1px solid #014da1",
            padding: "0.5rem 0",
            borderBottom: "1px solid #014da1",
          }}
        >
          {tableDate < currentDate
            ? "Pago Inmediato"
            : index === 0
            ? "Según lo acordado con su asesor"
            : simulatorArr[index][1]}
        </td>
        {isWithholding && (
          <td
            style={{
              borderRight: "1px solid #014da1",
              padding: "0.5rem 0",
              borderBottom: "1px solid #014da1",
            }}
          >
            ${Math.round(simulatorArr[index][4]).toLocaleString()}
          </td>
        )}
        <td
          style={{
            padding: "0.5rem 0",
            borderBottom: "1px solid #014da1",
          }}
        >
          $
          {Math.round(
            index === 0
              ? parseInt(paymentPremium) + parseFloat(simulatorArr[0][5])
              : simulatorArr[index][5]
          ).toLocaleString()}
        </td>
      </tr>
    );
  }

  const dateArrData = initDate.split("-");

  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "3.5rem",
          backgroundColor: "#014da1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="/assets/logo.webp"
          style={{
            width: "10rem",
            height: "2.5rem",
          }}
          alt=""
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "#014da1",
          fontWeight: "bold",
        }}
      >
        <p
          style={{
            marginLeft: "10%",
          }}
        >
          Valor Total de la Prima:
        </p>
        <p
          style={{
            marginRight: "10%",
          }}
        >
          ${Math.round(totalPremium).toLocaleString()}
        </p>
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "#014da1",
          color: "white",
          padding: "2rem 0",
          display: "flex",
          flexDirection: "column",
          rowGap: "1.5rem",
        }}
      >
        <div
          style={{
            margin: "0 10% 0 15%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p>Vigencia Inicial:</p>
          <p>
            {dateArrData.length > 1
              ? `${dateArrData[2]}/${dateArrData[1]}/${dateArrData[0]}`
              : "00/00/0000"}
          </p>
        </div>
        <div
          style={{
            margin: "0 10% 0 15%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p>Abono:</p>
          <p>${Math.round(paymentPremium).toLocaleString()}</p>
        </div>
        <div
          style={{
            margin: "0 10% 0 15%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p>Monto a Financiar:</p>
          <p>${loanAmount.toLocaleString()}</p>
        </div>
        <div
          style={{
            margin: "0 10% 0 15%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p>Tasa de Interés:</p>
          <p>{interestRate}%</p>
        </div>
        <div
          style={{
            margin: "0 10% 0 15%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p>Valor Cuota Mensual ({termMonth}):</p>
          <p>
            {!isWithholding
              ? `$${
                  isFinite(monthlyFee)
                    ? Math.round(monthlyFee + 2500).toLocaleString()
                    : 0
                }`
              : "Variable"}
          </p>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          padding: "1rem 0",
          color: "#014da1",
          display: "flex",
          flexDirection: "column",
          rowGap: "1rem",
        }}
      >
        <h1
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: "1.5rem",
            fontWeight: "500",
          }}
        >
          Como debes realizar los <strong>pagos de tu crédito</strong>
        </h1>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "40% 60%",
            margin: "0 25%",
            columnGap: "1rem",
          }}
        >
          <div>
            <table
              style={{
                border: "1px solid #014da1",
                width: "45.5rem",
                borderRadius: "0.5rem",
                marginLeft: "-15%",
              }}
            >
              <tbody>
                <tr
                  style={{
                    borderBottom: "1px solid #014da1",
                    textAlign: "center",
                    padding: "0.5rem 0",
                  }}
                >
                  <td
                    style={{
                      borderRight: "1px solid #014da1",
                      padding: "0.5rem 0",
                      borderBottom: "1px solid #014da1",
                    }}
                  >
                    Cuota
                  </td>
                  <td
                    style={{
                      borderRight: "1px solid #014da1",
                      padding: "0.5rem 0",
                      borderBottom: "1px solid #014da1",
                    }}
                  >
                    Fecha Pago Cuota
                  </td>
                  {isWithholding && (
                    <td
                      style={{
                        borderRight: "1px solid #014da1",
                        padding: "0.5rem 0",
                        borderBottom: "1px solid #014da1",
                      }}
                    >
                      Retención en la Fuente
                    </td>
                  )}
                  <td
                    style={{
                      padding: "0.5rem 0",
                      borderBottom: "1px solid #014da1",
                    }}
                  >
                    Valor a Pagar
                  </td>
                </tr>
                {elements}
              </tbody>
            </table>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            rowGap: "2rem",
            alignItems: "center",
          }}
        >
          <div>
            Dale clic a este botón desde tu <strong>pc</strong> o{" "}
            <strong>celular</strong> para ir a pagar
          </div>
          <div>
            <a
              style={{
                padding: "0.5rem 1rem",
                backgroundColor: "#4DFFFF",
                border: "1px solid #4DFFFF",
                borderRadius: "0.5rem",
                fontWeight: "bold",
                color: "#014da1",
                textDecoration: "none",
              }}
              href="https://pagos.crediseguro.co/auth/login"
            >
              Pagar en pasarela de pagos
            </a>
          </div>
          <div>
            También puedes <strong>escanear</strong> el siguiente código{" "}
            <strong>QR</strong>
          </div>
          <div
            style={{
              width: "6rem",
              height: "6rem",
            }}
          >
            <img style={{ width: "100%" }} src="/assets/qr.webp" alt="" />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "3rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "#014da1",
            fontWeight: "bold",
            backgroundColor: "#F4F4F4",
          }}
        >
          <p
            style={{
              marginLeft: "10%",
            }}
          >
            Total a Pagar Primer Mes:
          </p>
          <p
            style={{
              marginRight: "10%",
            }}
          >
            $
            {simulatorArr.length > 0
              ? diffMonths === 2
                ? Math.round(
                    simulatorArr[0][5] + simulatorArr[1][5]
                  ).toLocaleString()
                : diffMonths === 1 || diffMonths === 0 || diffMonths !== 2
                ? Math.round(
                    parseInt(paymentPremium) + parseFloat(simulatorArr[0][5])
                  ).toLocaleString()
                : 0
              : 0}
          </p>
        </div>
        <div
          style={{
            color: "#014da1",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            rowGap: "2rem",
          }}
        >
          <h1
            style={{
              fontSize: "1.2rem",
              fontWeight: "500",
              marginLeft: "10%",
            }}
          >
            Formas <strong>de pago</strong>
          </h1>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              margin: "0 10%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <img
                style={{ width: "8rem" }}
                src="/assets/efecty-logo.webp"
                alt=""
              />
              <p style={{ fontSize: "0.8rem", marginRight: "1rem" }}>
                Puntos <strong>efecty</strong> a nivel nacional
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "1rem",
                borderLeft: "1px solid #014da1",
                paddingLeft: "1rem",
              }}
            >
              <img
                style={{ width: "8rem" }}
                src="/assets/pse-logo.webp"
                alt=""
              />
              <p style={{ fontSize: "0.8rem" }}>
                <strong>www.crediseguro.co</strong> atraves de{" "}
                <strong>PSE</strong> con tarjeta de <strong>debito</strong> o{" "}
                <strong>crédito</strong>
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "50% 25% 25%",
            backgroundColor: "#014da1",
            color: "white",
            padding: "0.05rem 0",
            columnGap: "2rem",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginLeft: "10%",
              display: "flex",
              flexDirection: "column",
              rowGap: "1.5rem",
            }}
          >
            <div
              style={{
                border: "1px solid white",
                padding: "0.5rem",
                borderRadius: "1rem",
                fontSize: "0.7rem",
              }}
            >
              La unica forma de usar el seguro es estando al dia en los pagos
              ¿dudas o problemas con tus pagos? Llamanos 301-546-3565
              310-432-7864
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <img
                style={{ width: "2rem" }}
                src="/assets/wa-icon.webp"
                alt=""
              />
              <a
                style={{
                  border: "1px solid white",
                  color: "#014da1",
                  backgroundColor: "white",
                  fontWeight: "bold",
                  padding: "0.2rem 1rem",
                  borderRadius: "1rem",
                  textDecoration: "none",
                  fontSize: "0.5rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                href="https://api.whatsapp.com/send?phone=573015993123&text=¡Hola!%20Quiero%20más%20información%20sobre%20el%20pago%20o%20financiación%20con%20CrediSeguro%2C%20mi%20nombre%20es..."
              >
                chatea con uno de nuestros asesores
              </a>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "1rem",
                justifyContent: "center",
              }}
            >
              <a href="https://www.instagram.com/crediseguro/">
                <img
                  style={{ width: "2rem" }}
                  src="/assets/insta-logo.webp"
                  alt=""
                />
              </a>
              <a href="https://www.facebook.com/CrediSeguroFintech">
                <img
                  style={{ width: "2rem" }}
                  src="/assets/fb-logo.webp"
                  alt=""
                />
              </a>
              <a href="https://crediseguro.co/">
                <img
                  style={{ width: "2rem" }}
                  src="/assets/page-logo.webp"
                  alt=""
                />
              </a>
              <a href="https://www.linkedin.com/company/crediseguro/">
                <img
                  style={{ width: "2rem" }}
                  src="/assets/linkedin-logo.webp"
                  alt=""
                />
              </a>
            </div>
            <div
              style={{
                backgroundColor: "white",
                border: "1px solid white",
                color: "#014da1",
                textAlign: "center",
                fontSize: "0.6rem",
                fontWeight: "bold",
                borderRadius: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0.2rem 0.5rem",
              }}
            >
              La mejor opción para tu financiación
            </div>
          </div>
          <div style={{ marginRight: "10%" }}>
            <img
              style={{ width: "8rem" }}
              src="/assets/footer-img.webp"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimulatorPdf;
