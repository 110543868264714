import React from "react";

//Style
import "./SimulatorTablePreview.css";

const SimulatorTablePreview = ({
  loanAmount,
  interestRate,
  termMonth,
  isWithholding,
  monthlyFee,
  paymentDate,
  diffMonths,
  getCustomPaymentPremium,
  paymentPremium,
}) => {
  const formatDate = new Date(`${paymentDate}T00:00:00`);

  const interestRatePercetage = interestRate / 100;
  const withholdingPercentage = 0.07;

  let capitalBalance = 0;
  let capitalPayment = 0;

  let simulatorArr = [];

  if (termMonth > 0) {
    simulatorArr = Array.from({ length: termMonth }, () => Array(6).fill(0));

    for (let i = 0; i < simulatorArr.length; i++) {
      const newDate = new Date(formatDate);
      newDate.setMonth(formatDate.getMonth() + i);

      const day = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();

      simulatorArr[i][1] = `${day}/${month}/${year}`; //Setea la fecha en el indice 1 del arreglo simulatorArr
      simulatorArr[i][2] = loanAmount * interestRatePercetage;
      simulatorArr[i][3] = loanAmount - (monthlyFee - simulatorArr[i][2]);
      simulatorArr[i][4] = isWithholding
        ? simulatorArr[i][2] * withholdingPercentage
        : 0;
      simulatorArr[i][5] = monthlyFee + 2500 - simulatorArr[i][4];
      capitalPayment = monthlyFee - simulatorArr[0][2];
      capitalBalance = simulatorArr[0][3];

      if (i !== 0) {
        for (let i = 1; i < simulatorArr.length; i++) {
          if (i === 1) {
            simulatorArr[i][2] =
              (loanAmount - capitalPayment) * interestRatePercetage;
            capitalPayment = monthlyFee - simulatorArr[i][2];
            simulatorArr[i][3] = capitalBalance - capitalPayment;
            simulatorArr[i][4] = isWithholding
              ? simulatorArr[i][2] * withholdingPercentage
              : 0;
            simulatorArr[i][5] = monthlyFee + 2500 - simulatorArr[i][4];
            capitalBalance = simulatorArr[i][3];
          } else {
            simulatorArr[i][2] = capitalBalance * interestRatePercetage;
            capitalPayment = monthlyFee - simulatorArr[i][2];
            simulatorArr[i][3] = capitalBalance - capitalPayment;
            simulatorArr[i][4] = isWithholding
              ? simulatorArr[i][2] * withholdingPercentage
              : 0;
            simulatorArr[i][5] = monthlyFee + 2500 - simulatorArr[i][4];
            capitalBalance = simulatorArr[i][3];
          }
        }
      }
    }
  }

  const elements = [];

  for (let index = 0; index < termMonth; index++) {
    const currentDate = new Date();

    const dateParts = simulatorArr[index][1].split("/");
    const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;

    const tableDate = new Date(formattedDate);

    if (tableDate < currentDate && index <= diffMonths) {
      let sum = 0;

      sum =
        diffMonths > 1
          ? simulatorArr[1][5] + simulatorArr[0][5]
          : simulatorArr[0][5];

      getCustomPaymentPremium(sum);
    }

    if (tableDate >= currentDate && diffMonths === 0) {
      getCustomPaymentPremium(0);
    }

    elements.push(
      <tr className="stp-table-row-content">
        <td
          className={`stp-table-column-header ${
            index + 1 < termMonth ? "" : "last-row"
          }`}
        >
          {index + 1}
        </td>
        <td
          className={`stp-table-column-header ${
            index + 1 < termMonth ? "" : "last-row"
          }`}
        >
          {tableDate < currentDate
            ? "Pago Inmediato"
            : index === 0
            ? "Según lo acordado con su asesor"
            : simulatorArr[index][1]}
        </td>
        <td
          className={`stp-table-column-header ${
            index + 1 < termMonth ? "" : "last-row"
          }`}
        >
          ${Math.round(simulatorArr[index][4]).toLocaleString()}
        </td>
        <td
          className={`stp-table-column-header last ${
            index + 1 < termMonth ? "" : "last-row"
          }`}
        >
          $
          {Math.round(
            index === 0
              ? parseInt(paymentPremium) + parseFloat(simulatorArr[0][5])
              : simulatorArr[index][5]
          ).toLocaleString()}
        </td>
      </tr>
    );
  }

  return (
    <div className="simulator-table-preview-subcontainer">
      <div className="stp-table-info-container">
        <h1 className="stp-title">
          Como debes realizar los <strong>pagos de tu crédito</strong>
        </h1>
        <div className="stp-table-container">
          <table className="stp-table-elm">
            <tbody>
              <tr className="stp-table-row-header">
                <td className="stp-table-column-header">Cuota</td>
                <td className="stp-table-column-header">Fecha Pago Cuota</td>
                <td className="stp-table-column-header">
                  Retención en la Fuente
                </td>
                <td className="stp-table-column-header last">Valor a Pagar</td>
              </tr>
              {elements}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SimulatorTablePreview;
