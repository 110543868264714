import React from "react";

//Style
import "./MoreInfoDataFormRenovationBody.css";

//Data
import moreInfoDataRenovationInputs from "../../json/CreateCreditData/MoreInfoDataFormRenovationInputs.json";

//Components
import FormWithStepsComponent from "../FormWithStepsComponent/FormWithStepsComponent";
import FormDocsComponent from "../FormDocsComponent/FormDocsComponent";

const MoreInfoDataFormRenovationBody = () => {
  return (
    <section className="more-info-data-form-renovation-section">
      <div className="more-info-data-form-renovation-subcontainer">
        <div className="form-with-steps-supercontainer">
          <FormWithStepsComponent
            inputData={moreInfoDataRenovationInputs.data[0]}
            hasSteps={false}
            hasRestoreBtn={false}
          />
        </div>
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/bgs/woman-deco-img-1.webp"
          className="profile-deco"
          alt=""
        />
      </div>
    </section>
  );
};

export default MoreInfoDataFormRenovationBody;
