import React from "react";
import { appVersion } from "../../version";

//Style
import "./VersionTxtComponent.css";

const VersionTxtComponent = () => {
  return (
    <div className="version-txt-container">
      <p className="version-txt">Ver. {appVersion}</p>
    </div>
  );
};

export default VersionTxtComponent;
