import React, { useContext } from "react";

//Data
import loginData from "../../json/LoginScreen/LoginFormInputs.json";
import loginPopupData from "../../json/LoginScreen/button.json";

//Style
import "./LoginScreen.css";

//Component
import LoginForm from "../../components/LoginForm/LoginForm";
import LoadingScreenComponent from "../../components/LoadingScreenComponent/LoadingScreenComponent";
import PopUpComponent from "../../components/PopUpComponent/PopUpComponent";
import VersionTxtComponent from "../../components/VersionTxtComponent/VersionTxtComponent";

//Context
import { LoadingContext } from "../../context/LoadingContext";
import { PopupContext } from "../../context/PopupContext";

const LoginScreen = () => {
  const { loading } = useContext(LoadingContext);

  const { showPopup, popupData } = useContext(PopupContext);

  return (
    <>
      {loading && <LoadingScreenComponent />}
      {showPopup && (
        <PopUpComponent content={popupData} btnData={loginPopupData.data[0]} />
      )}
      <div className="login-screen-container">
        <VersionTxtComponent />
        <div className="login-screen-subcontainer">
          <img
            src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/logo.webp"
            alt=""
            className="login-logo"
          />
          <div className="login-txt-container">
            <h1 className="login-txt-welcome">¡Bienvenido!</h1>
            <p className="login-txt-subtitle">
              Inicia sesión en tu portal de intermediarios!!
            </p>
          </div>
          <div className="login-form-supercontainer">
            <LoginForm formData={loginData.data} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
