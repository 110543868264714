import React, { useState } from "react";

//Style
import "./AttachDocBody.css";

//Data
import attachDocInputData from "../../json/CreateCreditData/AttachDocDataInputs.json";

//Components
import FormDocsComponent from "../FormDocsComponent/FormDocsComponent";
import AttachExampleComponent from "../AttachExampleComponent/AttachExampleComponent";

const AttachDocBody = () => {
  const [currentValue, setCurrentValue] = useState("");

  const getCurrentValue = (value) => {
    setCurrentValue(value);
  };

  return (
    <section className="attach-doc-section">
      <div className="attach-doc-subcontainer">
        <div
          className={`attach-form-supercontainer ${
            currentValue === "" && "limit-width"
          }`}
        >
          <FormDocsComponent
            inputData={attachDocInputData.data[0]}
            getSelectedValue={getCurrentValue}
          />
          {currentValue && (
            <AttachExampleComponent
              adviceData={
                attachDocInputData.data[1].format[
                  currentValue === "digital"
                    ? 0
                    : currentValue === "tradicional"
                    ? 1
                    : currentValue === "extranjeria" && 2
                ]
              }
            />
          )}
        </div>
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/bgs/woman-deco-img-1.webp"
          className="profile-deco"
          alt=""
        />
      </div>
    </section>
  );
};

export default AttachDocBody;
