import React, { useEffect, useState, useContext } from "react";
import { BACKEND_API } from "../../constants/backendApi";

//Style
import "./CreateCreditBody.css";

//Data
import createCreditData from "../../json/CreateCreditData/CreateCreditData.json";
import createCreditMundialData from "../../json/CreateCreditData/CreateCreditMundialData.json";
import createCreditSolidariaData from "../../json/CreateCreditData/CreateCreditSolidariaData.json";
import createCreditHDIData from "../../json/CreateCreditData/CreateCreditHdiData.json";
import createCreditWFESegurosData from "../../json/CreateCreditData/CreateCreditWFESegurosData.json";

//Components
import FormWithStepsComponent from "../FormWithStepsComponent/FormWithStepsComponent";

//Context
import { LoadingContext } from "../../context/LoadingContext";
import { PopupContext } from "../../context/PopupContext";

const CreateCreditBody = ({ userData }) => {
  const token = localStorage.getItem("token");

  const { setLoading } = useContext(LoadingContext);

  const { setShowPopup } = useContext(PopupContext);

  const formId = localStorage.getItem("form_id");

  const insuranceId = localStorage.getItem("insurance_id");

  const [createCreditUserData, setCreateCreditUserData] = useState({});

  const [refreshData, setRefreshData] = useState(false);

  useEffect(() => {
    setShowPopup(false);
    setLoading(true);

    try {
      fetch(`${BACKEND_API}/api/credit-ocr?id=${formId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then(({ data }) => {
          setCreateCreditUserData(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.error(err);
    }
  }, [refreshData, token, setLoading, setShowPopup]);

  const getRefreshDataState = (newState) => {
    setRefreshData(newState);
  };

  return (
    <section className="create-credit-section">
      <div className="create-credit-subcontainer">
        <div className="form-with-steps-supercontainer">
          <FormWithStepsComponent
            hasSteps={true}
            createCreditUserData={createCreditUserData}
            refreshData={refreshData}
            getRefreshDataState={getRefreshDataState}
            inputData={
              insuranceId === "0013h00000GiwbWAAR"
                ? createCreditMundialData.data[0]
                : insuranceId === "0013h00000GiwbTAAR"
                ? createCreditSolidariaData.data[0]
                : insuranceId === "0013h00000DgTUOAA3"
                ? createCreditHDIData.data[0]
                : userData?.document === "71738809"
                ? createCreditWFESegurosData.data[0]
                : createCreditData.data[0]
            }
            hasRestoreBtn={true}
            userData={userData}
          />
        </div>
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/bgs/man-deco-img.webp"
          className="profile-deco"
          alt=""
        />
      </div>
    </section>
  );
};

export default CreateCreditBody;
