import React from "react";
import { Link } from "react-router-dom";

//Style
import "./PaymentMethodBody.css";

const PaymentMethodBody = () => {
  return (
    <section className="payment-method-section">
      <div className="payment-method-subcontainer">
        <div className="pm-info-container">
          <div className="pm-info-subcontainer">
            <div className="pm-info-content-container">
              <h1 className="pm-ic-title">
                Paga Por Internet con Tarjeta Débito o Crédito así:
              </h1>
              <div className="pm-ic-elms-container">
                <h2 className="pm-ic-e-title">
                  1. Si el Titular del Crédito es Persona Natural
                </h2>
                <ul className="pm-ic-list-container">
                  <li className="pm-ic-fe-list-elm">
                    Ingrese al Portal de Pagos:{" "}
                    <Link
                      className="pm-ic-se-le-anchor"
                      title="Crediseguro"
                      to="https://crediseguro.co/"
                    >
                      www.crediseguro.co
                    </Link>{" "}
                    y de clic en el botón{" "}
                    <strong>
                      <u>"¡Pago en línea!"</u>
                    </strong>
                  </li>
                  <li className="pm-ic-fe-list-elm">
                    En el campo "Documento" ingrese el No. de Documento del
                    Titular del Crédito
                  </li>
                  <li className="pm-ic-fe-list-elm">
                    En el campo "Contraseña" ingrese el No. de Documento del
                    Titular del crédito
                  </li>
                  <li className="pm-ic-fe-list-elm">
                    Dentro del Portal de pagos, de click sobre el crédito que
                    desea pagar y seleccione la(s) cuota(s) que desea pagar.
                  </li>
                </ul>
              </div>
              <div className="pm-ic-elms-container">
                <h2 className="pm-ic-e-title">
                  2. Si el Titular del Crédito es Persona Jurídica
                </h2>
                <ul className="pm-ic-list-container">
                  <li className="pm-ic-se-list-elm">
                    Ingrese al Portal de Pagos:{" "}
                    <Link
                      className="pm-ic-se-le-anchor"
                      title="Crediseguro"
                      to="https://crediseguro.co/"
                    >
                      www.crediseguro.co
                    </Link>{" "}
                    y de clic en el botón{" "}
                    <strong>
                      <u>"¡Pago en línea!"</u>
                    </strong>
                  </li>
                  <li className="pm-ic-se-list-elm">
                    En el campo "Documento" ingrese el Nit. SIN dígito de
                    verificación, guiones ni espacios
                  </li>
                  <li className="pm-ic-se-list-elm">
                    En el campo "Contraseña" ingrese el SIN dígito de
                    verificación, guiones ni espacios
                  </li>
                  <li className="pm-ic-se-list-elm">
                    Dentro del Portal de pagos, de click sobre el crédito que
                    desea pagar y seleccione la(s) cuota(s) que desea pagar.
                  </li>
                </ul>
              </div>
              <h1 className="pm-ic-title">Paga en Efecty así:</h1>
              <ol className="pm-ic-list-container">
                <li className="pm-ic-esl-list-elm">
                  Preséntate en cualquier Efecty a nivel nacional.
                </li>
                <li className="pm-ic-esl-list-elm">
                  Indica los siguientes números:
                  <p>
                    <strong>No. Convenio:</strong> 112992
                  </p>
                  <p>
                    <strong>No. Ref:</strong> Cada cuota tiene una referencia de
                    pago única las cuales puedes encontrar en el correo de
                    bienvenida o ingresando al portal de pago cliente.
                  </p>
                </li>
                <li className="pm-ic-esl-list-elm">
                  En Efecty te entregarán el comprobante de pago.
                </li>
              </ol>
              <div className="pm-ic-advice-content-container">
                <u className="pm-ic-ac-note-txt">
                  Nota: "Evita caer en fraudes: siempre verifica que los pagos
                  se realicen a la cuenta de la compañía con la que tienes tu
                  deuda y no a cuentas de personas naturales."
                </u>
                <strong className="pm-ic-ac-bold-txt">
                  CREDISEGURO S.A.S, NO maneja pagos informales por medios de
                  códigos QR, Daviplata, Nequi o cuentas de personas naturales.
                </strong>
              </div>
            </div>
          </div>
        </div>
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/bgs/man-deco-img.webp"
          className="pm-deco"
          alt=""
        />
      </div>
    </section>
  );
};

export default PaymentMethodBody;
