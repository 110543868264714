import React from "react";

//Style
import "./ListComponent.css";

const ListComponent = ({ infoComponent, listData }) => {
  const chunkSize = 4;

  const chunkedData = listData.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);

  return (
    <div className="list-container">
      <h1 className="list-title">{infoComponent.title}</h1>
      <div className="list-info-container">{infoComponent.subtitle}</div>
      <div className="list-subcontainer">
        <div className="list-header">
          {infoComponent.headers.map((header, i) => {
            return <h1 key={i}>{header.title}</h1>;
          })}
        </div>
        <div className="list-elms-container">
          <scroll-container>
            {chunkedData.map((chunk, pageIndex) => (
              <scroll-page key={pageIndex} id={`page-${pageIndex + 1}`}>
                <div className="scroll-list-data-container">
                  {chunk.map((list, i) => (
                    <div key={i} className="scroll-data-container">
                      {infoComponent.hasIcons ? (
                        <img
                          src={`https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/insurer/${list.Nombre.replace(
                            /\s/g,
                            "-"
                          )
                            .replace(/\./g, "")
                            .toLowerCase()}.webp`}
                          className="sd-img"
                          alt=""
                        />
                      ) : (
                        <p className="txt-data">{list.Nombre}</p>
                      )}

                      <div className="values-subcontainer">
                        {list.Total_Credito}
                      </div>
                    </div>
                  ))}
                </div>
              </scroll-page>
            ))}
          </scroll-container>
        </div>
        <div className="list-other-description-container">
          {infoComponent.bottom_text}
        </div>
      </div>
    </div>
  );
};

export default ListComponent;
