import React from "react";

//Style
import "./InfoTextComponent.css";

const InfoTextComponent = ({ infoTxt, hasHtmlTxt, infoTxtArr }) => {
  return (
    <div className="info-txt-container">
      {hasHtmlTxt ? (
        <div className="it-info-container">
          {infoTxtArr.map((infoTxt, i) => {
            return (
              <p className="info" key={i}>
                {infoTxt}
              </p>
            );
          })}
        </div>
      ) : (
        <p className="info">{infoTxt}</p>
      )}
    </div>
  );
};

export default InfoTextComponent;
