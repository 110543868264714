import React from "react";

//Style
import "./VerificationUserDataBody.css";

//Data
import verificationDataInputs from "../../json/CreateCreditData/VerificationDataInputs.json";

//Components
import FormWithStepsComponent from "../FormWithStepsComponent/FormWithStepsComponent";

const VerificationUserDataBody = () => {
  return (
    <section className="verification-data-section">
      <div className="verification-data-subcontainer">
        <div className="form-with-steps-supercontainer">
          <FormWithStepsComponent
            inputData={verificationDataInputs.data[0]}
            hasSteps={false}
            hasRestoreBtn={false}
          />
        </div>
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/bgs/create-credit-deco-img.webp"
          className="profile-deco"
          alt=""
        />
      </div>
    </section>
  );
};

export default VerificationUserDataBody;
