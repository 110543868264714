import React, { useContext } from "react";
import { Link } from "react-router-dom";

//Style
import "./ButtonComponent.css";

//Context
import { PopupContext } from "../../context/PopupContext";

const ButtonComponent = ({ btnData, hasBlock, block }) => {
  const { setShowPopup } = useContext(PopupContext);

  const handleDeleteCreditDataFromLS = () => {
    localStorage.removeItem("latestStep");
    localStorage.removeItem("processType");
    localStorage.removeItem("isCreditInProcess");
    setShowPopup(false);
  };

  return (
    <>
      {btnData.action === "close-popup" && (
        <Link
          onClick={() => setShowPopup(false)}
          className={btnData.page}
          type={btnData.type}
          title={btnData.title}
        >
          {btnData.text}
        </Link>
      )}
      {btnData.action === "submit" && (
        <button
          className={btnData.page}
          type={btnData.type}
          title={btnData.title}
          disabled={hasBlock ? block : false}
        >
          {btnData.text}
        </button>
      )}
      {btnData.action === "redirect" && (
        <Link
          className={btnData.page}
          type={btnData.type}
          title={btnData.title}
          disabled={hasBlock ? block : false}
          to={btnData.redirect}
        >
          {btnData.text}
        </Link>
      )}
      {btnData.action === "delete-data-ls" && (
        <Link
          onClick={handleDeleteCreditDataFromLS}
          className={btnData.page}
          type={btnData.type}
          title={btnData.title}
        >
          {btnData.text}
        </Link>
      )}
    </>
  );
};

export default ButtonComponent;
