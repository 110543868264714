import React, { useContext, useState } from "react";

//Data
import popupDataBtn from "../../json/LoginScreen/button.json";

//Components
import NavbarComponent from "../../components/NavbarComponent/NavbarComponent";
import SideMenusComponent from "../../components/SideMenusComponent/SideMenusComponent";
import LoadingScreenComponent from "../../components/LoadingScreenComponent/LoadingScreenComponent";
import NavbarMobile from "../../components/NavbarMobile/NavbarMobile";
import NavbarMobileBottom from "../../components/NavbarMobileBotton/NavbarMobileBottom";
import VersionTxtComponent from "../../components/VersionTxtComponent/VersionTxtComponent";
import CreditsInProcessBody from "../../components/CreditsInProcessBody/CreditsInProcessBody";
import PopupWithTableComponent from "../../components/PopupWithTableComponent/PopupWithTableComponent";
import PopUpComponent from "../../components/PopUpComponent/PopUpComponent";

//Context
import { LoadingContext } from "../../context/LoadingContext";
import { PopupContext } from "../../context/PopupContext";

const CreditsInProcessTableScreen = ({ userData, userMenuData }) => {
  const { loading } = useContext(LoadingContext);

  const [showPopupWithTable, setShowPopupWithTable] = useState(false);

  const [dataPopup, setDataPopup] = useState(null);

  const { showPopup, popupData } = useContext(PopupContext);

  const tableFields = [
    {
      name: "Docusign",
    },
    {
      name: "Estado",
    },
  ];

  const getDataFromCreditTable = (data, showPopup, type) => {
    if (type) {
      switch (type.type) {
        case "resend_docusign":
          setDataPopup(data);
          setShowPopupWithTable(showPopup);
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      {showPopup && (
        <PopUpComponent content={popupData} btnData={popupDataBtn.data[0]} />
      )}
      {showPopupWithTable && (
        <PopupWithTableComponent
          getDataFromCreditTable={getDataFromCreditTable}
          dataPopup={dataPopup}
          popupTableFields={tableFields}
          hasCustomBtn={true}
          popupTitle={"Reenviar Docusign"}
          hasDescription={false}
          type={"resend_docusign"}
        />
      )}
      {loading && <LoadingScreenComponent />}
      <div className="body-container">
        <VersionTxtComponent />
        <div className="body-subcontainer">
          <NavbarComponent userData={userData} />
          <NavbarMobile userData={userData} menuData={userMenuData} />
          <CreditsInProcessBody
            getDataFromCreditTable={getDataFromCreditTable}
            userData={userData}
          />
          <NavbarMobileBottom />
        </div>
        <SideMenusComponent menuData={userMenuData} />
      </div>
    </>
  );
};

export default CreditsInProcessTableScreen;