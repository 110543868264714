import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

//Style
import "./PieGraphComponent.css";

const PieGraphComponent = ({ labels, values, totalValue }) => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const data = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: [
          "#0969FE",
          "#38CFBC",
          "#43FFFF",
          "#7720E0",
          "#9DE32B",
          "#FF730C",
        ],
        borderColor: [
          "#0969FE",
          "#38CFBC",
          "#43FFFF",
          "#7720E0",
          "#9DE32B",
          "#FF730C",
        ],
        borderWidth: 0,
      },
    ],
  };

  return (
    <div className="pie-graph-container">
      <div className="pg-title-container">
        <h1 className="pg-title">Créditos Creados por Estado</h1>
      </div>
      <div className="pg-info-container">
        La siguiente gráfica contiene el total de créditos creados en nuestro
        sistema según su estado actual y el mes seleccionado.
      </div>
      <div className="total-container">
        Total de Créditos Creados: {totalValue}
      </div>
      <div className="pg-elms-container">
        <div className="pg-elm-container">
          <Pie data={data} />
        </div>
        <div className="pg-elm-info">
          <p>
            <strong>Vigente</strong> - Créditos ya desembolsados a la
            aseguradora y que están activos.
          </p>
          <p>
            <strong>Cancelado</strong> - Créditos cancelados por mora o de
            forma voluntaria.
          </p>
          <p>
            <strong>Anulado</strong> - Créditos que no finalizaron el proceso de
            creación, porque les hizo falta alguno de los dos requisitos.
          </p>
          <p>
            <strong>En Proceso de Cancelación</strong>- Créditos que se
            encuentran notificados a la aseguradora para la respectiva
            cancelación.
          </p>
          <p>
            <strong>En Proceso</strong>- Créditos que se encuentran pendientes
            de desembolso y/o créditos que están pendientes de algún requisito
            (pago de la cuota inicial y/o firma).
          </p>
          <p>
            <strong>Paz y Salvo</strong> - Créditos con la totalidad de
            cuotas pagas.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PieGraphComponent;
