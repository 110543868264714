import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

//Components
import LoginScreen from "../pages/LoginScreen/LoginScreen";
import AppRouter from "./AppRouter";
import PublicRouter from "./PublicRouter";
import PrivateRouter from "./PrivateRouter";

const LoginRouter = ({newVersion}) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRouter>
              <LoginScreen />
            </PublicRouter>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRouter>
              <AppRouter newVersion={newVersion} />
            </PrivateRouter>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default LoginRouter;
