import React, { useState } from "react";

//Style

//Data
import attachPolicyInputData from "../../json/CreateCreditData/AttachPolicyDataInputs.json";

//Components
import FormDocsComponent from "../FormDocsComponent/FormDocsComponent";
import AttachExampleComponent from "../AttachExampleComponent/AttachExampleComponent";

const AttachPolicyBody = () => {
  const [currentValue, setCurrentValue] = useState("");

  const getCurrentValue = (value) => {
    if (
      value === "2+MUNDIAL-0013h00000GiwbWAAR" ||
      value === "1+MUNDIAL-0013h00000GiwbWAAR" ||
      value === "1+SOLIDARIA-0013h00000GiwbTAAR" ||
      value === "2+SOLIDARIA-0013h00000GiwbTAAR" ||
      value === "3+SOLIDARIA-0013h00000GiwbTAAR" ||
      value === "4+SOLIDARIA-0013h00000GiwbTAAR" ||
      value === "1+EQUIDAD-0013h00000DgV5TAAV" ||
      value === "2+EQUIDAD-0013h00000DgV5TAAV" ||
      value === "1+SURA-0013h00000GiwbfAAB" ||
      value === "2+SURA-0013h00000GiwbfAAB"
    ) {
      setCurrentValue(value);
    } else {
      setCurrentValue("");
    }
  };

  return (
    <section className="attach-doc-section">
      <div className="attach-doc-subcontainer">
        <div
          className={`attach-form-supercontainer ${
            currentValue === "" && "limit-width"
          }`}
        >
          <FormDocsComponent
            inputData={attachPolicyInputData.data[0]}
            getSelectedValue={getCurrentValue}
          />
          {currentValue && (
            <AttachExampleComponent
              adviceData={
                attachPolicyInputData.data[1].format[
                  currentValue === "1+MUNDIAL-0013h00000GiwbWAAR"
                    ? 0
                    : currentValue === "2+MUNDIAL-0013h00000GiwbWAAR"
                    ? 1
                    : currentValue === "1+SOLIDARIA-0013h00000GiwbTAAR"
                    ? 2
                    : currentValue === "2+SOLIDARIA-0013h00000GiwbTAAR"
                    ? 3
                    : currentValue === "3+SOLIDARIA-0013h00000GiwbTAAR"
                    ? 4
                    : currentValue === "4+SOLIDARIA-0013h00000GiwbTAAR"
                    ? 5
                    : currentValue === "1+EQUIDAD-0013h00000DgV5TAAV"
                    ? 6
                    : currentValue === "2+EQUIDAD-0013h00000DgV5TAAV"
                    ? 7
                    : currentValue === "1+SURA-0013h00000GiwbfAAB"
                    ? 8
                    : currentValue === "2+SURA-0013h00000GiwbfAAB" && 9
                ]
              }
            />
          )}
        </div>
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/bgs/woman-deco-img-3.webp"
          className="profile-deco"
          alt=""
        />
      </div>
    </section>
  );
};

export default AttachPolicyBody;
