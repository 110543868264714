import React from "react";

//Style
import "./ProfileBody.css";

//Components
import ProfileFormComponent from "../ProfileFormComponent/ProfileFormComponent";

const ProfileBody = ({ userData, token }) => {
  return (
    <section className="profile-section">
      <div className="profile-subcontainer">
        <ProfileFormComponent userData={userData} token={token} />
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/bgs/woman-deco-img-1.webp"
          className="profile-deco"
          alt=""
        />
      </div>
    </section>
  );
};

export default ProfileBody;
