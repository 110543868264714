import React, { useState, useContext, useEffect } from "react";
import html2pdf from "html2pdf.js";

//Component
import InfoTextComponent from "../InfoTextComponent/InfoTextComponent";
import SimulatorPdf from "../SimulatorPdf/SimulatorPdf";
import SimulatorTablePreview from "../SimulatorTablePreview/SimulatorTablePreview";

//Style
import "./SimulatorBody.css";

//Context
import { PopupContext } from "../../context/PopupContext";

const SimulatorBody = ({ userData }) => {
  const [showInfoTxt, setShowInfoTxt] = useState("");

  const { setShowPopup, setPopupData } = useContext(PopupContext);

  const [showInfoDateTxt, setShowInfoDateTxt] = useState(false);

  const [showInitPremium, setShowInitPremium] = useState(false);

  const [monthlyInterestRate, setMonthlyInterestRate] = useState(0);

  const [, setAnnualInterestRate] = useState(0);

  const [loanAmount, setLoanAmount] = useState(0);

  const [formattedTotalPremium, setFormattedTotalPremium] = useState(0);

  const [formattedPremiumPayment, setFormattedPremiumPayment] = useState(0);

  const [monthlyFee, setMonthlyFee] = useState(0);

  const [isWithholding, setIsWithholding] = useState(false);

  const [paymentDate, setPaymentDate] = useState("");

  const [, setSelectedDate] = useState("");

  const [diffDates, setDiffDates] = useState(0);

  const [, setPaymentPremiumFromChild] = useState(0);

  const [simulatorData, setSimulatorData] = useState({
    init_date: "",
    total_premium: 0,
    payment_premium: 0,
    term_months: 0,
  });

  const getCustomPaymentPremium = (dataFromChild) => {
    setPaymentPremiumFromChild(dataFromChild);
  };

  useEffect(() => {
    setLoanAmount(simulatorData.total_premium - simulatorData.payment_premium);

    setMonthlyInterestRate(0);
    if (loanAmount <= 5000000) {
      setMonthlyInterestRate(userData.tasa1);
    } else if (loanAmount >= 5000000 && loanAmount <= 50000000) {
      setMonthlyInterestRate(userData.tasa2);
    } else if (loanAmount >= 50000000 && loanAmount <= 10000000000) {
      setMonthlyInterestRate(userData.tasa3);
    }

    const monthlyIRChange = monthlyInterestRate / 100;

    const numerator =
      loanAmount *
      (monthlyIRChange /
        (1 - Math.pow(1 + monthlyIRChange, -simulatorData.term_months)));
    const interest = +numerator.toFixed(2);

    setMonthlyFee(interest + 2500);
  }, [
    simulatorData.payment_premium,
    simulatorData.total_premium,
    simulatorData.term_months,
    loanAmount,
    monthlyInterestRate,
    userData?.tasa1,
    userData?.tasa2,
    userData?.tasa3,
  ]);

  useEffect(() => {
    setMonthlyInterestRate(userData.tasa1);
  }, [userData]);

  useEffect(() => {
    const decimalNumber = monthlyInterestRate / 100;

    const TEA = Math.pow(1 + decimalNumber, 12) - 1;
    const TEAValue = TEA * 100;

    setAnnualInterestRate(TEAValue.toFixed(2));
  }, [monthlyInterestRate, setAnnualInterestRate]);

  const convertToPDF = () => {
    const element = document.getElementById("section-pdf");
    const copy = element.cloneNode(true);

    element.style.display = "none";

    copy.style.display = "block";

    const tempContainer = document.createElement("div");
    tempContainer.appendChild(copy);

    const opt = {
      margin: 0,
      filename: `simulador-${simulatorData.term_months}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { format: "a3", unit: "mm", orientation: "portrait" },
    };

    html2pdf().from(tempContainer).set(opt).save();

    element.style.display = "none";
  };

  const handleSimulatorData = (e) => {
    if (e.target.title === "number") {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");
    }

    if (
      e.target.name === "payment_premium" &&
      e.target.value !== "" &&
      e.target.value.length < 2
    ) {
      setShowPopup(true);
      setPopupData({
        hasTitle: true,
        title: "Tener en Cuenta",
        info: `Por favor ten en cuenta que el abono inicial no incluye la primera cuota del préstamo. Si deseas que la primera cuota esté incluida en tu abono inicial, te recomendamos que te pongas en contacto con tu ejecutivo para obtener más información y asistencia personalizada.`,
        type: "",
      });
    }

    if (e.target.title === "date") {
      const currentDate = new Date();

      const limitDate = new Date();
      limitDate.setDate(currentDate.getDate() - 59);

      const inputDate = new Date(`${e.target.value}T00:00:00`);

      const diffMonths =
        (currentDate.getFullYear() - inputDate.getFullYear()) * 12 +
        (currentDate.getMonth() - inputDate.getMonth());

      setDiffDates(diffMonths);

      setSelectedDate(e.target.value);

      const dayOfMonth = inputDate.getDate();
      const month = inputDate.getMonth() + 1;
      const year = inputDate.getFullYear();

      if (dayOfMonth >= 1 && dayOfMonth < 16) {
        const paymentDay = `${year}-${month < 10 ? `0${month}` : month}-25`;
        setPaymentDate(paymentDay);
      } else if (dayOfMonth > 15 && dayOfMonth <= 31) {
        const nextMonth = month < 12 ? month + 1 : 1;
        const nextYear = nextMonth === 1 ? year + 1 : year;
        const paymentDay = `${nextYear}-${
          nextMonth < 10 ? `0${nextMonth}` : nextMonth
        }-10`;
        setPaymentDate(paymentDay);
      }

      if (inputDate <= limitDate) {
        e.target.value = "";
        setShowPopup(true);
        setPopupData({
          title: "Error",
          info: `La vigencia inicial no puede ser anterior o igual al ${limitDate.toLocaleDateString(
            "es-CO",
            {
              year: "numeric",
              month: "long",
              day: "numeric",
            }
          )}, por favor comuníquese con su intermediario.`,
          type: "error",
        });
      }
    }

    setSimulatorData({
      ...simulatorData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormatNumbers = (e) => {
    const inputValue = e.target.value.replace(/,/g, "").replace(/[^0-9]/g, "");

    const formatted = parseFloat(inputValue).toLocaleString();

    if (e.target.name === "total_premium") {
      setFormattedTotalPremium(inputValue === "" ? 0 : formatted);
    } else {
      setFormattedPremiumPayment(inputValue === "" ? 0 : formatted);
    }
  };

  const handleDescarga = () => {
    const tripleAValidateNit = [
      "900323037",
      "900028783",
      "9003230374",
      "900323037.",
      "9003230372",
      "9003230375",
      "860056330",
      "800029569",
      "890801770",
      "800170725",
    ];

    let file = "";

    if (tripleAValidateNit.includes(userData.document)) {
      file =
        "https://pagina-crediseguro.s3.us-east-1.amazonaws.com/portal-crediseguro/assets/docs/diciembre2024/Simulador+CrediSeguro+Triple+AAA+Diciembre+2024.xls";
    } else if (userData.document === "900424467") {
      file =
        "https://pagina-crediseguro.s3.us-east-1.amazonaws.com/portal-crediseguro/assets/docs/diciembre2024/Simulador+CrediSeguro+TuSeguro+Diciembre+2024.xls";
    } else if (userData.document === "901070688") {
      file =
        "https://pagina-crediseguro.s3.us-east-1.amazonaws.com/portal-crediseguro/assets/docs/diciembre2024/Simulador+CrediSeguro+Giraldo+Seguros+Diciembre+2024.xls";
    } else if (userData.document === "860020058") {
      file =
        "https://pagina-crediseguro.s3.us-east-1.amazonaws.com/portal-crediseguro/assets/docs/diciembre2024/Simulador+CrediSeguro+Distoyota+Diciembre+2024.xls";
    } else {
      file =
        "https://pagina-crediseguro.s3.us-east-1.amazonaws.com/portal-crediseguro/assets/docs/diciembre2024/Simulador+CrediSeguro+Retefuente+Diciembre+2024.xlsx";
    }

    const downloadUrl = document.createElement("a");
    downloadUrl.href = file;
    downloadUrl.setAttribute("download", "Simulador Crediseguro 2024.xlsx");

    downloadUrl.click();
  };

  return (
    <section className="simulator-section">
      <div className="simulator-subcontainer">
        <div className="sim-calculator-container">
          <div className="sim-calculator-elm-container">
            <h1 className="sce-title">
              Solicita <strong>tu Crédito</strong> de Manera{" "}
              <strong>Inmediata</strong>
            </h1>
            <form
              onChange={handleSimulatorData}
              className="sce-inputs-container"
            >
              <div className="sce-grid-inputs">
                <div className="info-subtitle-container date-info">
                  {showInfoDateTxt && (
                    <InfoTextComponent
                      infoTxt={
                        "Selecciona la fecha de vigencia inicial dando clic al icono de calendario."
                      }
                    />
                  )}
                </div>
                <label className="style-label">
                  <div className="date-label-container">
                    <img
                      onMouseOver={() => setShowInfoDateTxt(true)}
                      onMouseLeave={() => setShowInfoDateTxt(false)}
                      className="subtitle-info-icon"
                      src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/warning-icon.webp"
                      alt=""
                    />
                    <h2 className="label-subtitle">
                      Vigencia <strong>Inicial</strong>:
                    </h2>
                  </div>
                  <input
                    title="date"
                    className="input-txt date"
                    type="date"
                    name="init_date"
                    id=""
                    onKeyDown={(e) => e.preventDefault()}
                  />
                </label>
                <label className="style-label">
                  <h2 className="label-subtitle">
                    Valor Total <strong>de la Prima</strong>:
                  </h2>
                  <input
                    onChange={handleFormatNumbers}
                    name="total_premium"
                    title="number"
                    className="input-txt"
                    type="text"
                    placeholder="0"
                    disabled={simulatorData.init_date === "" ? true : false}
                  />
                </label>
              </div>
              <div className="sce-grid-inputs">
                <div className="info-subtitle-container init-premium-info">
                  {showInitPremium && (
                    <InfoTextComponent
                      infoTxt={
                        "¿Desea hacer un abono inicial? digítelo a continuación."
                      }
                    />
                  )}
                </div>
                <label className="style-label">
                  <div className="date-label-container">
                    <img
                      onMouseOver={() => setShowInitPremium(true)}
                      onMouseLeave={() => setShowInitPremium(false)}
                      className="subtitle-info-icon"
                      src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/warning-icon.webp"
                      alt=""
                    />
                    <h2 className="label-subtitle">
                      Abono <strong>de la Prima</strong>:
                    </h2>
                  </div>
                  <input
                    onChange={handleFormatNumbers}
                    name="payment_premium"
                    title="number"
                    className="input-txt"
                    type="text"
                    placeholder="0"
                    disabled={simulatorData.init_date === "" ? true : false}
                  />
                </label>
                <label className="style-label">
                  <h2 className="label-subtitle">
                    Plazo <strong>en Meses</strong>:
                  </h2>
                  <select
                    title="select"
                    className="input-select"
                    name="term_months"
                    id=""
                    disabled={simulatorData.init_date === "" ? true : false}
                  >
                    <option value="0">--Ninguna--</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                  </select>
                </label>
              </div>
              <div className="radio-btns-container">
                <div className="info-subtitle-container">
                  {showInfoTxt && (
                    <InfoTextComponent
                      infoTxt={
                        "Si el cliente es agente retenedor se le aplicara la respectiva retención en la fuente."
                      }
                    />
                  )}
                </div>
                <div className="label-subtitle-with-info">
                  <img
                    onMouseOver={() => setShowInfoTxt(true)}
                    onMouseLeave={() => setShowInfoTxt(false)}
                    className="subtitle-info-icon"
                    src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/warning-icon.webp"
                    alt=""
                  />
                  <h2 className="label-subtitle">
                    Es <strong>Persona Jurídica</strong>?:
                  </h2>
                </div>
                <div className="radio-opt-container">
                  <label className="radio-label">
                    Si
                    <input
                      className="input-radio"
                      type="radio"
                      name="isWithholding"
                      id=""
                      checked={isWithholding ? true : false}
                      onClick={() => setIsWithholding(true)}
                    />
                  </label>
                  <label className="radio-label">
                    No
                    <input
                      className="input-radio"
                      type="radio"
                      name="isWithholding"
                      id=""
                      checked={!isWithholding ? true : false}
                      onClick={() => setIsWithholding(false)}
                    />
                  </label>
                </div>
              </div>
            </form>
            <div className="payment-info-container">
              <h2 className="pi-title">
                Formas <strong>de Pago</strong>
              </h2>
              <div className="payment-methods-info-container">
                <div className="pmi-elm-container">
                  <img
                    src="/assets/efecty-logo.webp"
                    alt=""
                    className="pmi-elm-icon"
                  />
                  <p>
                    Puntos <strong>efecty</strong> a nivel nacional
                  </p>
                </div>
                <div className="pmi-elm-container bordered">
                  <img
                    src="/assets/pse-logo.webp"
                    alt=""
                    className="pmi-elm-icon"
                  />
                  <p>
                    <strong>www.crediseguro.co</strong> atraves de{" "}
                    <strong>PSE</strong> con tarjeta de <strong>debito</strong>{" "}
                    o <strong>crédito</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="advise-container">
              <p>*Sujeto a validación por parte de CrediSeguro.</p>
            </div>
          </div>
          <div className="sim-calculator-result-container">
            <h1 className="scr-title">
              Estos son los <strong>Valores a Pagar</strong>
            </h1>
            <div className="scr-info-container">
              <div className="src-info-subcontainer deco-field">
                <p>Valor Total de la Prima:</p>
                <p>${formattedTotalPremium}</p>
              </div>
              <div className="src-info-subcontainer">
                <p>Abono:</p>
                <p>${formattedPremiumPayment}</p>
              </div>
              <div className="src-info-subcontainer">
                <p>Monto a Financiar:</p>
                <p>${loanAmount.toLocaleString()}</p>
              </div>
              <div className="src-info-subcontainer">
                <p>Tasa de Interés Mensual:</p>
                <p>{monthlyInterestRate}%</p>
              </div>
              <div className="src-info-subcontainer deco-field">
                <p>Valor Cuota Mensual ({simulatorData.term_months}):</p>
                <p>
                  {!isWithholding && "$"}
                  {isWithholding
                    ? "Variable"
                    : isNaN(monthlyFee) || !isFinite(monthlyFee)
                    ? 0
                    : Math.round(monthlyFee).toLocaleString()}
                </p>
              </div>
              {isWithholding && (
                <div className="src-info-subcontainer advise">
                  <p>
                    Si el cliente es persona jurídica estará sujeto a revisión
                    para determinar si aplica retención en la fuente.
                  </p>
                </div>
              )}
            </div>
            <div className="generate-file-btn-container">
              {simulatorData.init_date &&
                simulatorData.init_date !== "" &&
                simulatorData.term_months &&
                simulatorData.term_months !== "0" &&
                simulatorData.total_premium &&
                simulatorData.total_premium !== "" && (
                  <button
                    onClick={convertToPDF}
                    title="Imprimir Simulación"
                    className="print-btn"
                  >
                    Descargar Simulación
                  </button>
                )}
              <button
                title="Descargar Documento del Simulador"
                className="print-btn"
                onClick={handleDescarga}
              >
                Descargar Simulador
              </button>
            </div>
          </div>
        </div>
        <div className="sim-table-preview-container">
          <SimulatorTablePreview
            loanAmount={loanAmount}
            interestRate={monthlyInterestRate}
            termMonth={simulatorData.term_months}
            isWithholding={isWithholding}
            monthlyFee={monthlyFee - 2500}
            paymentDate={paymentDate}
            diffMonths={diffDates}
            getCustomPaymentPremium={getCustomPaymentPremium}
            paymentPremium={simulatorData.payment_premium}
          />
        </div>
        <div
          id="section-pdf"
          className="sim-preview-container"
          style={{ display: "none" }}
        >
          <SimulatorPdf
            totalPremium={simulatorData.total_premium}
            paymentPremium={simulatorData.payment_premium}
            loanAmount={loanAmount}
            interestRate={monthlyInterestRate}
            termMonth={simulatorData.term_months}
            isWithholding={isWithholding}
            monthlyFee={monthlyFee - 2500}
            paymentDate={paymentDate}
            diffMonths={diffDates}
            initDate={simulatorData.init_date}
          />
        </div>
      </div>
    </section>
  );
};

export default SimulatorBody;
