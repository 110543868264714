import React, { useEffect, useState, useContext } from "react";
import { BACKEND_API } from "../../constants/backendApi";

//Style
import "./DashboardBody.css";

//Component
import PieGraphComponent from "../PieGraphComponent/PieGraphComponent";
import ListComponent from "../ListComponent/ListComponent";
import BarGraphComponent from "../BarGraphComponent/BarGraphComponent";

//Context
import { LoadingContext } from "../../context/LoadingContext";

const DashboardBody = ({ fetchData, listComponentData }) => {
  const { setLoading } = useContext(LoadingContext);

  const [pieGraphData, setPieGraphData] = useState([]);

  const [anualTotalCredits, setAnualTotalCredits] = useState(0);

  const [pieGraphDataValue, setPieGraphDataValue] = useState([]);

  const [totalValue, setTotalValue] = useState(0);

  const [listInsurerData, setListInsurerData] = useState([]);

  const [listConsultant, setListConsultant] = useState([]);

  const [barGraphData, setBarGraphData] = useState([]);

  const [filter, setFilter] = useState(false);

  const [currentMonth, setCurrentMonth] = useState(0);

  const [currentMonthName, setCurrentMonthName] = useState("");

  const [, setCurrentYear] = useState(0);

  const [isPast, setIsPast] = useState(false);

  const [filterData, setFilterData] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });

  useEffect(() => {
    const selectMonth = document.getElementById("month");

    const selectYear = document.getElementById("year");

    const currentMonth = new Date().getMonth() + 1;

    const currentYear = new Date().getFullYear();

    if (selectYear) {
      for (let year = 2023; year <= currentYear; year++) {
        const option = document.createElement("option");
        option.value = year;
        option.textContent = year;
        selectYear.appendChild(option);
      }

      selectYear.value = currentYear.toString();
    }

    if (selectMonth) {
      selectMonth.selectedIndex = currentMonth;
    }

    setCurrentMonth(currentMonth);
    setCurrentYear(currentYear);
  }, [setCurrentYear]);

  useEffect(() => {
    const currentMonthDate = new Date().getMonth() + 1;

    const currentYearDate = new Date().getFullYear();

    switch (currentMonth) {
      case 1:
        setCurrentMonthName("Enero");
        break;
      case 2:
        setCurrentMonthName("Febrero");
        break;
      case 3:
        setCurrentMonthName("Marzo");
        break;
      case 4:
        setCurrentMonthName("Abril");
        break;
      case 5:
        setCurrentMonthName("Mayo");
        break;
      case 6:
        setCurrentMonthName("Junio");
        break;
      case 7:
        setCurrentMonthName("Julio");
        break;
      case 8:
        setCurrentMonthName("Agosto");
        break;
      case 9:
        setCurrentMonthName("Septiembre");
        break;
      case 10:
        setCurrentMonthName("Octubre");
        break;
      case 11:
        setCurrentMonthName("Noviembre");
        break;
      case 12:
        setCurrentMonthName("Diciembre");
        break;
      default:
        break;
    }

    if (isNaN(currentMonth)) {
      setCurrentMonthName("");
    }

    if (
      filterData.year < currentYearDate ||
      (filterData.year === currentYearDate && currentMonth < currentMonthDate)
    ) {
      setIsPast(true);
    } else {
      setIsPast(false);
    }
  }, [currentMonth, filter, filterData?.year]);

  useEffect(() => {
    setLoading(true);

    const token = localStorage.getItem("token");

    setCurrentMonth(filterData.month);

    const body = {
      tipo_servicio: "Historico_de_Ventas",
      mes: filterData.month,
      anio: filterData.year,
    };

    try {
      fetch(`${BACKEND_API}${fetchData.endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then(({ data }) => {
          if (data) {
            setPieGraphData([]);

            const namesArray = data.map((item) => item.Nombre);
            const valuesArr = data.map((item) => item.Total_Credito);

            const values = data.map((item) => {
              const numericValue =
                typeof item.Total_Credito === "number" &&
                !isNaN(item.Total_Credito)
                  ? item.Total_Credito
                  : parseFloat(item.Total_Credito);

              return isNaN(numericValue) ? 0 : numericValue;
            });

            const totalValue = values.reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            );

            setTotalValue(totalValue);

            setPieGraphData((prevData) => [
              ...new Set([...prevData, ...namesArray]),
            ]);
            setPieGraphDataValue(valuesArr);
          } else {
            setTotalValue(0);
            setPieGraphData(["N/A"]);
            setPieGraphDataValue([0]);
          }

          const bodyListInsurer = {
            tipo_servicio: "Ventas_por_Aseguradora",
            mes: filterData.month,
            anio: filterData.year,
          };
          try {
            fetch(`${BACKEND_API}${fetchData.endpoint}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(bodyListInsurer),
            })
              .then((res) => res.json())
              .then(({ data }) => {
                if (data) {
                  const sortedData = data.slice().sort((a, b) => {
                    return b.Total_Credito - a.Total_Credito;
                  });

                  setListInsurerData(sortedData);
                } else {
                  setListInsurerData([]);
                }

                const bodyListConsultant = {
                  tipo_servicio: "Ventas_Asesor",
                  mes: filterData.month,
                  anio: filterData.year,
                };
                try {
                  fetch(`${BACKEND_API}${fetchData.endpoint}`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(bodyListConsultant),
                  })
                    .then((res) => res.json())
                    .then(({ data }) => {
                      if (data) {
                        const sortedData = data.slice().sort((a, b) => {
                          return b.Total_Credito - a.Total_Credito;
                        });

                        setListConsultant(sortedData);
                      } else {
                        setListConsultant([]);
                      }

                      const bodyBarGraph = {
                        tipo_servicio: "Ventas_Mensuales",
                        anio: filterData.year,
                      };

                      const monthsData = new Array(12).fill(0);
                      try {
                        fetch(`${BACKEND_API}${fetchData.endpoint}`, {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                          },
                          body: JSON.stringify(bodyBarGraph),
                        })
                          .then((res) => res.json())
                          .then(({ data }) => {
                            if (data) {
                              let totalSum = 0;
                              data.forEach((item) => {
                                const monthNumber = parseInt(item.Nombre);
                                if (monthNumber >= 1 && monthNumber <= 12) {
                                  monthsData[monthNumber - 1] =
                                    item.Total_Credito;
                                }

                                const creditValue = parseFloat(
                                  item.Total_Credito
                                );

                                if (!isNaN(creditValue)) {
                                  totalSum += creditValue;
                                }
                              });
                              setAnualTotalCredits(totalSum);
                              setBarGraphData(monthsData);
                              setLoading(false);
                            } else {
                              setBarGraphData(monthsData);
                              setLoading(false);
                            }
                          });
                      } catch (err) {
                        console.log(err);
                      }
                    });
                } catch (err) {
                  console.log(err);
                }
              });
          } catch (err) {
            console.log(err);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [
    filter,
    fetchData?.endpoint,
    filterData?.month,
    filterData?.year,
    setLoading,
  ]);

  const handleGetDataFilter = (e) => {
    setFilterData({
      ...filterData,
      [e.target.name]: parseInt(e.target.value),
    });
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setFilter(!filter);
  };

  return (
    <section className="dashboard-section">
      <div className="dashboard-subcontainer">
        <div className="dashboard-title-container">
          <h1 className="dashboard-title">
            {!isPast
              ? `Asi Vamos en ${currentMonthName} 
             ${filterData.year}`
              : `Asi Nos Fue en ${currentMonthName}  ${filterData.year}`}
          </h1>
        </div>
        <div className="dashboard-subtitle-container">
          <p className="dashboard-subtitle">
            Total de créditos creados {totalValue} y total de créditos
            desembolsados{" "}
            {currentMonthName !== ""
              ? barGraphData[currentMonth - 1]
              : anualTotalCredits}
          </p>
        </div>
        <form
          className="filters-container"
          onChange={handleGetDataFilter}
          onSubmit={handleSubmitFilter}
        >
          <label>
            <select className="filter-select" name="year" id="year"></select>
          </label>
          <label>
            <select className="filter-select" name="month" id="month">
              <option value="">--Ninguno--</option>
              <option value="1">Enero</option>
              <option value="2">Febrero</option>
              <option value="3">Marzo</option>
              <option value="4">Abril</option>
              <option value="5">Mayo</option>
              <option value="6">Junio</option>
              <option value="7">Julio</option>
              <option value="8">Agosto</option>
              <option value="9">Septiembre</option>
              <option value="10">Octubre</option>
              <option value="11">Noviembre</option>
              <option value="12">Diciembre</option>
            </select>
          </label>
          <button title="Filtrar" className="filter-btn">
            Filtrar
          </button>
        </form>
        <div className="first-half-container">
          <div className="pie-graph-container">
            <PieGraphComponent
              labels={pieGraphData}
              values={pieGraphDataValue}
              totalValue={totalValue}
            />
          </div>
          <div className="sell-insurer-graph-container">
            <ListComponent
              infoComponent={listComponentData[0]}
              listData={listInsurerData}
            />
          </div>
        </div>
        <div className="second-half-container">
          <div className="sell-broker-graph-container">
            <ListComponent
              infoComponent={listComponentData[1]}
              listData={listConsultant}
            />
          </div>
          <div className="bar-graph-supercontainer">
            <BarGraphComponent
              barGraphData={barGraphData}
              selectedYear={filterData.year}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardBody;
