import React, { useContext } from "react";

//Components
import LoadingScreenComponent from "../../components/LoadingScreenComponent/LoadingScreenComponent";
import VersionTxtComponent from "../../components/VersionTxtComponent/VersionTxtComponent";
import NavbarComponent from "../../components/NavbarComponent/NavbarComponent";
import NavbarMobile from "../../components/NavbarMobile/NavbarMobile";
import NavbarMobileBottom from "../../components/NavbarMobileBotton/NavbarMobileBottom";
import SideMenusComponent from "../../components/SideMenusComponent/SideMenusComponent";
import PaymentMethodBody from "../../components/PaymentMethodBody/PaymentMethodBody";

//Context
import { LoadingContext } from "../../context/LoadingContext";

const PaymentMethodScreen = ({ userData, userMenuData }) => {
  const { loading } = useContext(LoadingContext);

  return (
    <>
      {loading && <LoadingScreenComponent />}
      <div className="body-container">
        <VersionTxtComponent />
        <div className="body-subcontainer">
          <NavbarComponent userData={userData} />
          <NavbarMobile userData={userData} menuData={userMenuData} />
          <PaymentMethodBody />
          <NavbarMobileBottom />
        </div>
        <SideMenusComponent menuData={userMenuData} />
      </div>
    </>
  );
};

export default PaymentMethodScreen;
